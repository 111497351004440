import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';

import BlockStyles from '../BlockStyles';
import Block from './Block';
import ImageBlock from './ImageBlock';

const TwoColumnContainer = styled.div`
  .two-columns-block {
    margin: 2rem 0;
  }
`;

const BlockContainer = styled.div`
  display: flex;

  ${(ifProp('$orientation'),
  css`
    flex-direction: ${prop('$orientation')};
  `)}

  ${ifProp(
    { $template: 'products-landing' },
    css`
      justify-content: space-evenly;
    `,
    css`
      flex-wrap: wrap;
    `
  )}

  @media(max-width: ${prop('theme.breakpoints.sm')}) {
    flex-direction: column;
  }
`;

const propTypes = {
  primary: PropTypes.object,
  fields: PropTypes.array,
  label: PropTypes.string,
  className: PropTypes.string,
  template: PropTypes.string
};

const defaultProps = {
  primary: {},
  fields: [],
  label: '',
  className: '',
  template: ''
};

function TwoColumns({ primary, fields, label, className, template }) {
  return (
    <BlockStyles data={primary} template={template}>
      <TwoColumnContainer>
        <div className={className + ' block'}>
          <BlockContainer
            $template={template}
            $orientation={primary.orientation}
          >
            {className === 'two-columns-block' && (
              <Block fields={fields} template={template} />
            )}
            {className === 'two-columns-image' && (
              <ImageBlock
                primary={primary}
                fields={fields}
                label={label}
                template={template}
              />
            )}
          </BlockContainer>
        </div>
      </TwoColumnContainer>
    </BlockStyles>
  );
}

TwoColumns.propTypes = propTypes;
TwoColumns.defaultProps = defaultProps;

export default TwoColumns;
