import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import React from 'react';
import { Spinner } from 'reactstrap';

import ArticlesBlock from '../ArticlesBlock';
import CardGroup from '../CardGroup';
import CtaBlock from '../CtaBlock';
import Disclaimer from '../Disclaimer';
import SpinnerContainer from '../SpinnerContainer';
import TextBlock from '../TextBlock';
import TwoColumns from '../TwoColumns';

const loadingSpinner = (
  <SpinnerContainer height='500'>
    <Spinner size='lg' />
  </SpinnerContainer>
);
// lazy-load components that are generally more complicated and not needed by the homepage
const TableBlock = loadable(() => import('../TableBlock'));
const EmbeddedVideoPlayer = loadable(() => import('../EmbeddedVideoPlayer'));
const ProductsCta = loadable(() => import('../ProductsCta/ProductsCta'));
const TextTable = loadable(() => import('../TextTable'));
const DocumentList = loadable(() => import('../DocumentList'));
const CalendarBlock = loadable(() => import('../CalendarBlock'));
const MailchimpForm = loadable(() => import('../MailchimpForm'));
const ContentCardsBlock = loadable(() => import('../ContentCardsBlock'));
const ContentPanelBlock = loadable(() =>
  import('../Community/Portal/ContentPanelBlock')
);
const AgreementsList = loadable(() =>
  import('../Community/Portal/AgreementsList')
);
const ProfileInformationForm = loadable(() =>
  import('../Community/Portal/ProfileInformationForm')
);
const PrimaryEmailChangeForm = loadable(() =>
  import('../Community/Portal/PrimaryEmailChangeForm')
);
const BrokerageAccountsDisplay = loadable(() =>
  import('../Community/Portal/BrokerageAccountsDisplay')
);
const PanelLinks = loadable(() => import('../Community/Portal/PanelLinks'));
const NewsletterArchive = loadable(() =>
  import('../Community/Portal/NewsletterArchive')
);
const RegistrationTextBlock = loadable(() =>
  import('../Community/Registration/RegistrationTextBlock')
);
const Alert = loadable(() => import('../Community/Registration/Alert'));
const InvoiceList = loadable(() => import('../Community/Portal/InvoiceList'));
const PriceMovement = loadable(() => import('../Widgets/PriceMovement'), {
  fallback: loadingSpinner
});
const WidgetWrapper = loadable(() =>
  import('../Widgets/SharedComponents/WidgetWrapper')
);
const CurrentStatus = loadable(() => import('../Widgets/CurrentStatus'));
const OptionChainBlock = loadable(
  () => import('../Widgets/OptionChain/OptionChainBlock'),
  {
    fallback: loadingSpinner
  }
);
const FuturesContractTable = loadable(
  () => import('../Widgets/FuturesContractTable/FuturesContractTable'),
  {
    fallback: loadingSpinner
  }
);
const HistoricalOptionChain = loadable(
  () => import('../Widgets/OptionChain/HistoricalOptionChain'),
  {
    fallback: loadingSpinner
  }
);
const ButtonLinkBlock = loadable(
  () => import('../ButtonLink/ButtonLinkBlock'),
  {
    fallback: loadingSpinner
  }
);
const EmbeddedDocument = loadable(() => import('../EmbeddedDocument'), {
  fallback: loadingSpinner
});
const LatestArticlesBlock = loadable(
  () => import('../NewsComponents/LatestArticles/LatestArticlesBlock'),
  { fallback: loadingSpinner }
);

const propTypes = {
  body: PropTypes.array,
  pageUid: PropTypes.string,
  productName: PropTypes.string,
  productSymbol: PropTypes.string,
  template: PropTypes.string,
  titleHidden: PropTypes.bool
};

const defaultProps = {
  body: [],
  pageUid: '',
  productName: '',
  productSymbol: '',
  template: '',
  titleHidden: false
};

function BodySection({
  bodySection,
  pageUid,
  productSymbol,
  productName,
  template,
  titleHidden
}) {
  if (!bodySection.primary) {
    return null;
  }

  switch (bodySection.slice_type) {
    case 'calendar_slice':
      return (
        <CalendarBlock
          content={bodySection.primary}
          titleHidden={titleHidden}
        />
      );
    case 'text_block':
      const {
        text_block_header,
        tooltip,
        disclaimer_text,
        sidenav_only
      } = bodySection.primary;
      const fields = bodySection.items;
      const content =
        Object.prototype.toString.call(fields) === '[object Array]' &&
        fields.length !== 0
          ? fields
          : [];

      return (
        <TextBlock
          content={content}
          disclaimer={disclaimer_text}
          header={text_block_header}
          template={template}
          titleHidden={template === 'homepage' ? sidenav_only : titleHidden}
          tooltip={tooltip}
          primary={bodySection.primary}
          embeddedVideo={bodySection.primary.embedded_video}
        />
      );
    case 'table__10_cols_':
      return (
        <TableBlock
          head={bodySection.primary}
          body={bodySection.items}
          template={template}
          titleHidden={titleHidden}
        />
      );
    case 'mailchimp_form':
      if (!bodySection.primary.mailchimp_form_id) {
        return null;
      }

      return (
        <MailchimpForm
          primary={bodySection.primary}
          additionalParameters={bodySection.items}
          template={template}
        />
      );
    case 'two_columns':
    case 'two_columns_image':
      if (bodySection.items.length === 0) {
        return null;
      }

      return (
        <TwoColumns
          className={
            bodySection.slice_type === 'two_columns'
              ? 'two-columns-block'
              : 'two-columns-image'
          }
          primary={bodySection.primary}
          fields={bodySection.items}
          label={bodySection.slice_label}
          template={template}
        />
      );
    case 'price_movement':
      return (
        <PriceMovement
          fields={bodySection.items}
          symbol={productSymbol}
          productName={productName}
          template={template}
          title={bodySection.primary.block_title}
          tooltip={bodySection.primary.tooltip}
          contractLink={bodySection.primary.contract_link}
          contractLinkText={bodySection.primary.contract_link_text}
          widgetType={bodySection.slice_type}
        />
      );
    case 'option_chain':
      return (
        <OptionChainBlock
          symbol={productSymbol}
          template={template}
          title={bodySection.primary.table_title}
          fields={bodySection.items}
        />
      );
    case 'market_comparisons':
    case 'contract_specs':
    case 'options_specs':
      return (
        <WidgetWrapper
          fields={bodySection.items}
          primary={bodySection.primary}
          symbol={productSymbol}
          template={template}
          sliceType={bodySection.slice_type}
        />
      );
    case 'futures_contract_data':
      return (
        <FuturesContractTable
          symbol={productSymbol}
          template={template}
          primary={bodySection.primary}
          optionsFields={bodySection.items}
        />
      );
    case 'historical_options_data':
      return (
        <HistoricalOptionChain
          symbol={productSymbol}
          template={template}
          primary={bodySection.primary}
          fields={bodySection.items}
        />
      );
    case 'card_group':
      return (
        <CardGroup
          primary={bodySection.primary}
          data={bodySection.items}
          template={template}
        />
      );
    case 'current_status':
      return (
        <CurrentStatus
          buttonLink={bodySection.primary.button_link}
          buttonText={bodySection.primary.button_text}
          template={template}
        />
      );
    case 'embed_video':
      return (
        <EmbeddedVideoPlayer
          video={bodySection.primary.embed_video}
          template={template}
        />
      );
    case 'articles_block':
      return (
        <ArticlesBlock primary={bodySection.primary} template={template} />
      );
    case 'cta_block':
      return (
        <CtaBlock
          content={bodySection.primary}
          fields={bodySection.items}
          template={template}
        />
      );
    case 'product_cta':
      return (
        <ProductsCta
          primary={bodySection.primary}
          columns={bodySection.items}
          template={template}
        />
      );
    case 'text_table':
      return <TextTable cells={bodySection.items} template={template} />;
    case 'document_list':
      return (
        <DocumentList
          primary={bodySection.primary}
          items={bodySection.items}
          template={template}
        />
      );
    case 'disclaimer_text':
      return <Disclaimer primary={bodySection.primary} template={template} />;
    case 'button_link':
      return (
        <ButtonLinkBlock primary={bodySection.primary} template={template} />
      );
    case 'alert':
      return <Alert data={bodySection} template={template} />;
    case 'embedded_document':
      return <EmbeddedDocument data={bodySection} template={template} />;
    case 'content_cards_block':
      return (
        <ContentCardsBlock
          data={bodySection}
          pageUid={pageUid}
          template={template}
        />
      );
    case 'content_panel_block':
      return (
        <ContentPanelBlock
          data={bodySection}
          pageUid={pageUid}
          template={template}
        />
      );
    case 'agreements_list':
      return (
        <AgreementsList
          data={bodySection}
          pageUid={pageUid}
          template={template}
        />
      );
    case 'profile_information_form':
      return <ProfileInformationForm data={bodySection} />;
    case 'primary_email_change_form':
      return <PrimaryEmailChangeForm data={bodySection} />;
    case 'trading_accounts':
      return <BrokerageAccountsDisplay data={bodySection} />;
    case 'community_text_block':
      return <RegistrationTextBlock data={bodySection} template={template} />;
    case 'panel_links':
      return <PanelLinks data={bodySection} pageUid={pageUid} />;
    case 'invoice_list':
      return <InvoiceList data={bodySection} />;
    case 'latest_articles_in_category':
      return (
        <LatestArticlesBlock
          data={bodySection}
          mode={'category'}
          template={template}
        />
      );
    case 'latest_articles_with_tags':
      return (
        <LatestArticlesBlock
          data={bodySection}
          mode={'tag'}
          template={template}
        />
      );
    case 'newsletter_archive':
      return <NewsletterArchive data={bodySection} />;
    default:
      return null;
  }
}

BodySection.propTypes = propTypes;
BodySection.defaultProps = defaultProps;

export default BodySection;
