import findKey from 'lodash-es/findKey';
import get from 'lodash-es/get';
import isNumber from 'lodash-es/isNumber';

import {
  FUTURES_MONTH_CODES,
  FUTURES_START_DATE_FORMAT,
  FUTURES_START_DATE_FORMATTED,
  FUTURES_START_MONTH,
  YOUTUBE_THUMBNAILS
} from './constants';
import dayjs from './dayjs';
import linkResolver from './linkResolver';
import { isHoliday } from './marketTimeUtils';

export const formatTime = (time) => {
  return dayjs(time).format('MM/DD/YYYY hh:mm [CT]');
};

export const formatDate = (timeString) => {
  return dayjs(timeString).format('MM/DD/YYYY');
};

export const findLastTradeTime = (feedsTimes) => {
  if (!feedsTimes) {
    return 0;
  }

  const timeValues = feedsTimes.map(
    (value) => (value && value.Trade && value.Trade.time) || 0
  );

  return timeValues.length > 0 ? Math.max(...timeValues) : 0;
};

export const checkForPrice = (price) => {
  return !isNaN(price) && !!price && price !== 'NaN';
};

export const getPriceChange = (currentPrice, prevDayClosePrice) => {
  if (
    typeof currentPrice === 'number' &&
    typeof prevDayClosePrice === 'number'
  ) {
    return currentPrice - prevDayClosePrice;
  } else {
    return 'N/A';
  }
};

export const setBool = (value) => {
  return value === 'true';
};

export const capitalizeFirstLetter = (string) => {
  if (string) {
    if (typeof string !== 'string') {
      throw new Error('Input must be a string');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return '';
  }
};

export const trimTeaser = (teaser, maxLength = 160) => {
  if (teaser && maxLength < teaser.length) {
    let trimmedTeaser = teaser.substr(0, maxLength);

    //re-trim if we are in the middle of a word
    trimmedTeaser = trimmedTeaser.substr(
      0,
      Math.min(trimmedTeaser.length, trimmedTeaser.lastIndexOf(' '))
    );

    return trimmedTeaser + '...';
  }

  return teaser;
};

export const getHeadingType = (elem) => {
  if (elem.slice_type === 'calendar_slice') {
    const { calendar_title } = elem.primary;
    return calendar_title.richText[0] && calendar_title.richText[0].type;
  }

  if (elem.slice_type === 'text_block') {
    const { text_block_header } = elem.primary;
    return text_block_header.richText[0] && text_block_header.richText[0].type;
  }

  if (elem.slice_type === 'table__10_cols_') {
    const { table_title } = elem.primary;
    return table_title.richText[0] && table_title.richText[0].type;
  }
};

export const getHeadingValue = (elem) => {
  if (elem.slice_type === 'calendar_slice') {
    const {
      calendar_title: { richText }
    } = elem.primary;
    return richText;
  }

  if (elem.slice_type === 'text_block') {
    const {
      text_block_header: { richText }
    } = elem.primary;
    return richText;
  }

  if (elem.slice_type === 'table__10_cols_') {
    const {
      table_title: { richText }
    } = elem.primary;
    return richText;
  }
};

// TODO: remove?
export const getTableTooltips = (column, head, headerKeys) => {
  const columnObject = column && column.richText && column.richText[0];
  const labelParent = columnObject && columnObject.spans;

  // Checks for label and if label matches the column field for tooltip
  // it will return the value which is rendered as the tooltip content
  if (columnObject && headerKeys.includes(column.label)) {
    const labelValue = column.label;

    return head[labelValue];

    // Checks for label if the text in prismic has been highlighted, which
    // adds a span wrapped and pushes the label data a few levels deeper in the
    // object that is returned; the end result is still checking to see if the
    // label matches the column field for toolip
  } else if (
    columnObject &&
    headerKeys.includes(get(labelParent, 'data.label'))
  ) {
    const labelValue = get(labelParent, 'data.label');

    return head[labelValue];
  }
};

export const getAllMonthSymbols = (symbol, backMonthAndYear, years) => {
  const [backMonth, backYear] = backMonthAndYear.split(' ');
  const firstYearOfBusiness = years.shift();
  const startMonth = FUTURES_START_MONTH;
  const backMonthCode = FUTURES_MONTH_CODES[backMonth];

  const allMonths = Object.values(FUTURES_MONTH_CODES);
  const symbolsArray = [];

  // Get 2020 symbols
  for (let i = startMonth; i < allMonths.length; i++) {
    symbolsArray.push(
      `/${symbol}${allMonths[i]}${firstYearOfBusiness}:SMFE{=d}`
    );
  }

  years.forEach((year) => {
    for (let i = 0; i < allMonths.length; i++) {
      // This check is mostly input validation. If for some reason 'years' includes year(s) beyond the backMonthAndYear,
      /// we don't want to add those to the symbol list
      if (parseInt(year) > parseInt(backYear)) {
        break;
      }

      symbolsArray.push(`/${symbol}${allMonths[i]}${year}:SMFE{=d}`);

      if (allMonths[i] === backMonthCode && year === backYear) {
        break;
      }
    }
  });

  return symbolsArray.toString();
};

export const trimFuturesSymbols = (value) => {
  const colonIndex = value.indexOf(':');
  const futureSymbolEndIndex = colonIndex - 3;

  return value.slice(1, futureSymbolEndIndex);
};

export const trimFuturesSuffix = (value) => {
  let returnValue = value;
  const slashIndex = returnValue.indexOf('/');
  returnValue = returnValue.slice(slashIndex + 1, returnValue.length);

  const colonIndex = returnValue.indexOf(':');
  if (colonIndex > -1) {
    returnValue = returnValue.slice(0, colonIndex);
  }

  return returnValue;
};

export const findContractMonth = (symbol) => {
  const colonIndex = symbol.indexOf(':');
  const monthIndex = colonIndex - 3;
  const month = symbol.slice(monthIndex, monthIndex + 1);

  return findKey(FUTURES_MONTH_CODES, (item) => item === month);
};

export const checkForNumValue = (value, defaultNaN = 0) => {
  return value && !isNaN(value) ? value : defaultNaN;
};

export const formatDecimals = (value) => {
  return isNumber(value) ? value.toFixed(2) : value;
};

export const findContractExpirationDate = (date, symbol, holidayList) => {
  if (!date) {
    return;
  }

  const fridayDayValue = 5;
  const numberOfWeeks = 3;

  let thisFriday = dayjs()
    .set({
      month: date.month(),
      year: date.year()
    })
    .set('day', fridayDayValue);

  // If the next Friday is in next month, then we want to subtract a week.
  if (thisFriday.month() !== date.month()) {
    thisFriday = thisFriday.subtract(1, 'week');
  }

  // Find out the week number of this Friday.
  const currentWeekOfMonth = Math.ceil(thisFriday.date() / 7);

  // Get the expiration date by adding (or subtracting if it's negative) weeks
  let expDate = thisFriday.add(numberOfWeeks - currentWeekOfMonth, 'week');

  // if the expiration date we found is a market holiday, we need to subtract one day
  if (isHoliday(expDate, holidayList)) {
    expDate = expDate.subtract(1, 'day');
  }
  // The expDate here should be the third Friday of the month and then we are
  // adding three days to get the Monday immediately following after it. Using
  // the fourth Monday of the month proved to be very unreliable.
  expDate = expDate
    .date(expDate.date() + 3)
    .hour(7)
    .minute(0)
    .second(0)
    .millisecond(0);

  if (symbol && symbol.includes('SMO')) {
    // SMO's expiration date is one week sooner than all other products
    return dayjs(expDate).subtract(7, 'day');
  }

  return expDate;
};

export const findContractStartDate = (symbol, month, year) => {
  const firstDayOfPreviousMonth = dayjs()
    .month(month)
    .year(year)
    .date(1)
    .subtract(1, 'month');

  const dayOfWeek = firstDayOfPreviousMonth.day();
  /*
     if the first day of the month is Saturday, we need to add 6 days to reach the first Friday,
     14 more days to reach the  third Friday, and then 3 more days to reach the following Monday. 6 + 14 + 3 = 23
     For SMO, we need to reach the second Friday, meaning we only need 16 days maximum increase. 6 + 7 + 3 = 16
  */
  const maxDaysToIncrease = symbol === 'SMO' ? 16 : 23;
  let daysToIncrease = maxDaysToIncrease;

  if (dayOfWeek !== 6) {
    daysToIncrease = daysToIncrease - dayOfWeek - 1;
  }

  return firstDayOfPreviousMonth.add(daysToIncrease, 'day');
};

export const findFrontBackMonth = (expDate) => {
  const today = dayjs();
  let frontMonth = '';
  let backMonth = '';

  if (today >= expDate) {
    frontMonth = dayjs()
      .add(1, 'month')
      .format('MMMM YYYY');
    backMonth = dayjs()
      .add(2, 'month')
      .format('MMMM YYYY');
  } else {
    frontMonth = dayjs().format('MMMM YYYY');
    backMonth = dayjs()
      .add(1, 'month')
      .format('MMMM YYYY');
  }

  return [frontMonth, backMonth];
};

export const roundToNearestHundred = (numberVal) => {
  if (isNaN(numberVal)) {
    return 0;
  }

  return Math.round(numberVal / 100) * 100;
};

export const monthAndYearSelectOptions = (endYear) => {
  const options = {
    months: [],
    years: []
  };

  for (let month = 0; month < 12; month++) {
    const current = dayjs()
      .month(month)
      .date(1);
    options.months.push({
      // react-select has a bug where it will show the placeholder when value === 0. One workaround for this is to use strings instead of numbers for the values
      value: `${month}`,
      label: current.format('MMMM')
    });
  }

  for (let year = 2020; year <= endYear; year++) {
    options.years.push({
      value: year,
      label: `${year}`
    });
  }

  const asc = (element1, element2) => +element1.value > +element2.value;
  options.months = options.months.sort(asc);
  options.years = options.years.sort(asc);

  return options;
};

export const validateKeyIsNumber = (object, key) => {
  return !!object && !!object[key] && !isNaN(object[key]);
};

// Checking to see how many years of historical contract data there should be.
// Then passing the year values into an array so that data can be looped over.
export const historicalContractsYears = (endDate) => {
  const years = [];
  let startDate = dayjs(
    FUTURES_START_DATE_FORMATTED,
    FUTURES_START_DATE_FORMAT
  ).startOf('year');
  const expDate = findContractExpirationDate(endDate);

  if (endDate.isSameOrAfter(expDate)) {
    endDate = endDate.add(1, 'month');
  }
  endDate = endDate.add(1, 'month').endOf('month');

  while (startDate.isBefore(endDate)) {
    years.push(startDate.format('YY'));
    startDate = startDate.add(1, 'year');
  }

  return years;
};

export const getYoutubeThumbnailUrl = (
  video,
  quality = YOUTUBE_THUMBNAILS.large
) => {
  const isValidQuality = findKey(
    YOUTUBE_THUMBNAILS,
    (item) => item.indexOf(quality) >= 0
  );
  const videoQuality = isValidQuality ? quality : YOUTUBE_THUMBNAILS.large;

  const embedUrl = get(video, 'embed_url');

  if (embedUrl && embedUrl.length > 0) {
    let videoId;
    if (embedUrl.includes('watch')) {
      videoId = new URLSearchParams(embedUrl.split('?')[1]).get('v');
    } else {
      const splitUrl = embedUrl.split('/');
      videoId = splitUrl[splitUrl.length - 1];
    }

    return videoId
      ? `https://i\.ytimg\.com/vi_webp/${videoId}/${videoQuality}.webp`
      : '';
  }

  return '';
};

export const getLinkProps = (link, queryParameters) => {
  const { uid, type, link_type, url, target, parentUid } = link;
  const isDocumentLink = link_type === 'Document';

  let urlLink = isDocumentLink
    ? linkResolver({ uid: uid, type: type, parentUid: parentUid })
    : url;
  if (queryParameters && Object.keys(queryParameters).length > 0) {
    const stringifiedParams = Object.keys(queryParameters)
      .map((key) => `${key}=${queryParameters[key]}`)
      .join('&');
    urlLink += `?${stringifiedParams}`;
  }

  return isDocumentLink
    ? { to: urlLink, target: target }
    : { href: urlLink, as: 'a', target: target };
};

export const isNullValue = (value) => {
  return value === null || value === undefined;
};
