import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

const defaultState = null;
const IndexContext = React.createContext(defaultState);
IndexContext.displayName = 'IndexContext';

const indexContextQuery = graphql`
  query IndexContextQuery {
    allPrismicProduct {
      edges {
        node {
          data {
            futures_symbol
            dxfeed_symbol
          }
        }
      }
    }
  }
`;

function IndexProvider({ children }) {
  return (
    <StaticQuery
      query={`${indexContextQuery}`}
      render={(data) => {
        const indices = {};
        data.allPrismicProduct.edges.forEach((productDoc) => {
          const product = productDoc.node.data;
          indices[product.futures_symbol] = productDoc.node.data.dxfeed_symbol;
        });

        return (
          <IndexContext.Provider value={indices}>
            {children}
          </IndexContext.Provider>
        );
      }}
    />
  );
}

export { IndexProvider };

export default IndexContext;
