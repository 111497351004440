import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import PriceFeeds from '../components/PriceFeeds';
import ProductBody from '../components/ProductBody';
import MainCol from '../components/PageBody/MainCol';
import SideRailsMenu from '../components/SideRailsMenu';
import ColumnWrapper from '../components/PageBody/styled/ColumnWrapper';
import { GatsbyIpfFileProvider } from '../context/GatsbyIpfFileContext';
import PageContainer from '../components/layout-components/PageContainer';
import CombinedContextProvider from '../context/CombinedContextProvider';
import { MonthProvider } from '../context/MonthContext';
import { MarketDataProvider } from '../context/MarketDataContext';
import { DateProvider } from '../context/DateContext';
import { pageModes } from '../utils/constants';
import actions from '../state/actions';
import { getPersistor } from '../state/createStore';
import { IndexProvider } from '../context/IndexContext';

const propTypes = {
  data: PropTypes.object
};

const defaultProps = {
  data: null
};

function Product({ data: queryResult }) {
  const pageData = queryResult.prismicProduct.data;
  const sideRailsMenuData = { isProductsMenu: true };

  if (!pageData) {
    return null;
  }

  const {
    futures_symbol,
    overview_embed_video,
    header_banner_symbol,
    header_banner,
    title,
    specification_title,
    quote_title,
    data_title,
    overview_title,
    overview_description,
    body: spec_body,
    quote_body,
    data_body,
    overview_body
  } = pageData;
  const pageBodyMapping = {
    [pageModes.SPEC]: spec_body,
    [pageModes.QUOTE]: quote_body,
    [pageModes.DATA]: data_body,
    [pageModes.OVERVIEW]: overview_body
  };
  const pageTitleMapping = {
    [pageModes.SPEC]: specification_title,
    [pageModes.QUOTE]: quote_title,
    [pageModes.DATA]: data_title,
    [pageModes.OVERVIEW]: overview_title
  };
  const productTitle = title.text && title.text;
  const pageMode = useSelector(
    (state) => state.pageModes && state.pageModes[futures_symbol]
  );
  const dispatch = useDispatch();

  const bannerSymbol =
    header_banner_symbol && header_banner_symbol.text
      ? header_banner_symbol.text
      : futures_symbol;

  const feedData = {
    isProductPage: true,
    symbol: futures_symbol
  };

  // only load options data if the current subpage has an option chain on it
  // generally this means the user is on the quote page for a product with options
  const shouldLoadOptionsData =
    (pageBodyMapping[pageMode] || []).findIndex(
      (slice) => slice.slice_type === 'option_chain'
    ) > -1;

  useEffect(() => {
    if (!pageMode) {
      dispatch({
        type: actions.SET_PAGE_MODE,
        payload: {
          [futures_symbol]: pageModes.OVERVIEW
        }
      });
    }
  }, [pageMode]);

  return (
    <Layout
      headerBanner={header_banner && header_banner.url}
      title={productTitle}
      symbol={bannerSymbol}
    >
      <PersistGate persistor={getPersistor()}>
        <CombinedContextProvider
          contexts={[GatsbyIpfFileProvider, DateProvider, IndexProvider]}
        >
          <MarketDataProvider
            symbol={futures_symbol}
            shouldLoadOptionsData={shouldLoadOptionsData}
          >
            <PageContainer>
              <SideRailsMenu
                title='Markets'
                titleUrl='/futures-markets'
                data={sideRailsMenuData}
              />
              <MonthProvider symbol={futures_symbol}>
                <MainCol>
                  <ColumnWrapper>
                    <ProductBody
                      productName={productTitle}
                      futures_symbol={futures_symbol}
                      pageBodyMapping={pageBodyMapping}
                      embeddedVideo={overview_embed_video}
                      overviewDescription={overview_description}
                      template={'product'}
                      displayTitle
                      pageTitle={pageTitleMapping[pageMode]}
                    />
                  </ColumnWrapper>
                </MainCol>
              </MonthProvider>
              <PriceFeeds feedData={feedData} />
            </PageContainer>
          </MarketDataProvider>
        </CombinedContextProvider>
      </PersistGate>
    </Layout>
  );
}

export const Head = ({ data: queryResult }) => {
  const pageData = queryResult.prismicProduct.data;

  if (!pageData) {
    return null;
  }

  const { seo_title, seo_meta_description, seo_image, title } = pageData;

  return (
    <SEO
      title={seo_title || title.text}
      description={seo_meta_description}
      image={seo_image}
    />
  );
};

export const query = graphql`
  query ProductPageQuery($id: String!) {
    prismicProduct(id: { eq: $id }) {
      uid
      _previewable
      data {
        dxfeed_symbol
        futures_symbol
        header_banner_symbol {
          text
        }
        header_banner {
          url
          dimensions {
            height
            width
          }
        }
        seo_title
        seo_meta_description
        seo_image {
          url
        }
        sort_order
        specification_title {
          richText
          text
        }
        quote_title {
          richText
          text
        }
        data_title {
          richText
          text
        }
        overview_title {
          richText
          text
        }
        title {
          text
        }
        body {
          ... on PrismicProductDataBodyTextBlock {
            slice_type
            primary {
              text_block_header {
                richText
                text
              }
              tooltip {
                richText
                text
              }
              disclaimer_text {
                richText
                text
              }
              embedded_video {
                html
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              text_block_content {
                richText
                text
                html
              }
              button_text {
                text
              }
              button_link {
                url
                target
                type
                uid
                link_type
                tags
              }
            }
          }
          ... on PrismicProductDataBodyMarketComparisons {
            slice_type
            slice_label
            primary {
              header {
                text
              }
              subheader {
                text
              }
              tooltip {
                richText
                text
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              market_name {
                text
              }
              market_image {
                url
                dimensions {
                  width
                  height
                }
              }
              mobile_image {
                url
                dimensions {
                  width
                  height
                }
              }
              size {
                text
              }
              capital_required {
                text
              }
            }
          }
          ... on PrismicProductDataBodyContractSpecs {
            slice_type
            slice_label
            primary {
              header {
                text
              }
              tooltip {
                richText
                text
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              label
              label_link {
                richText
                text
              }
              value {
                richText
                text
              }
              dxfeed_value
              dxfeed_previous_value
              display_order
            }
          }
          ... on PrismicProductDataBodyOptionsSpecs {
            slice_type
            slice_label
            primary {
              header {
                text
              }
              tooltip {
                richText
                text
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              label
              label_link {
                richText
                text
              }
              value {
                richText
                text
              }
              dxfeed_value
              dxfeed_previous_value
              display_order
            }
          }
        }
        overview_body {
          ... on PrismicProductDataOverviewBodyTextBlock {
            slice_type
            primary {
              text_block_header {
                richText
                text
              }
              tooltip {
                richText
                text
              }
              disclaimer_text {
                richText
                text
              }
              embedded_video {
                html
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              text_block_content {
                richText
                text
                html
              }
              button_text {
                text
              }
              button_link {
                url
                target
                type
                uid
                link_type
                tags
              }
            }
          }
          ... on PrismicProductDataOverviewBodyPriceMovement {
            slice_type
            slice_label
            primary {
              block_title {
                text
              }
              tooltip {
                richText
                text
              }
              contract_link_text {
                richText
                text
              }
              contract_link {
                uid
                type
              }
            }
            items {
              label
              data_type
              dxfeed_value
              dxfeed_previous_value
              override_1
              override_2
            }
          }
          ... on PrismicProductDataOverviewBodyOptionChain {
            slice_type
            slice_label
            primary {
              table_title {
                text
              }
            }
            items {
              name
              data_type
              dxfeed_value
            }
          }
          ... on PrismicProductDataOverviewBodyMarketComparisons {
            slice_type
            slice_label
            primary {
              header {
                text
              }
              subheader {
                text
              }
              tooltip {
                richText
                text
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              market_name {
                text
              }
              market_image {
                url
                dimensions {
                  width
                  height
                }
              }
              mobile_image {
                url
                dimensions {
                  width
                  height
                }
              }
              size {
                text
              }
              capital_required {
                text
              }
            }
          }
          ... on PrismicProductDataOverviewBodyContractSpecs {
            slice_type
            slice_label
            primary {
              header {
                text
              }
              tooltip {
                richText
                text
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              label
              label_link {
                richText
                text
              }
              value {
                richText
                text
              }
              dxfeed_value
              dxfeed_previous_value
              display_order
            }
          }
          ... on PrismicProductDataOverviewBodyOptionsSpecs {
            slice_type
            slice_label
            primary {
              header {
                text
              }
              tooltip {
                richText
                text
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              label
              label_link {
                richText
                text
              }
              value {
                richText
                text
              }
              dxfeed_value
              dxfeed_previous_value
              display_order
            }
          }
          ... on PrismicProductDataOverviewBodyEmbedVideo {
            slice_type
            primary {
              embed_video {
                html
                embed_url
                title
                provider_name
                thumbnail_url
              }
              thumbnail {
                url
              }
            }
          }
          ... on PrismicProductDataOverviewBodyTwoColumnsImage {
            slice_type
            slice_label
            items {
              additional_text {
                richText
                text
              }
              image {
                url
                dimensions {
                  height
                  width
                }
              }
              text_block {
                richText
                text
              }
              title {
                richText
                text
              }
              button_link {
                url
              }
              button_link_text {
                richText
                text
              }
            }
          }
          ... on PrismicProductDataOverviewBodyLatestArticlesWithTags {
            slice_type
            primary {
              title {
                richText
                text
              }
            }
            items {
              tag
            }
          }
        }
        quote_body {
          ... on PrismicProductDataQuoteBodyPriceMovement {
            slice_type
            slice_label
            primary {
              block_title {
                text
              }
              tooltip {
                richText
                text
              }
              contract_link_text {
                richText
                text
              }
              contract_link {
                uid
                type
              }
            }
            items {
              label
              data_type
              dxfeed_value
              dxfeed_previous_value
              override_1
              override_2
            }
          }
          ... on PrismicProductDataQuoteBodyOptionChain {
            slice_type
            slice_label
            primary {
              table_title {
                text
              }
            }
            items {
              name
              data_type
              dxfeed_value
            }
          }
        }
        data_body {
          ... on PrismicProductDataDataBodyFuturesContractData {
            slice_type
            slice_label
            primary {
              contract_title {
                richText
                text
              }
              contract_description {
                richText
                text
              }
              contract_tooltip_title {
                richText
                text
              }
              contract_tooltip {
                richText
                text
              }
            }
          }
          ... on PrismicProductDataDataBodyHistoricalOptionsData {
            slice_type
            slice_label
            primary {
              options_title {
                richText
                text
              }
              description {
                richText
                text
              }
              tooltip_title {
                richText
                text
              }
              tooltip {
                richText
                text
              }
            }
            items {
              name
              dxfeed_value
              decimal_points
              default_value
            }
          }
          ... on PrismicProductDataDataBodyTextBlock {
            slice_type
            primary {
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
              text_block_header {
                richText
                text
              }
            }
            items {
              text_block_content {
                richText
                text
                html
              }
            }
          }
        }
      }
    }
  }
`;

Product.propTypes = propTypes;
Product.defaultProps = defaultProps;

export default withPrismicPreview(Product);
