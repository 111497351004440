export const MARKET_TIMEZONE_ID = 'America/Chicago';

// Data Source constants refer to Select options in prismic to choose whether
// to show index data or futures data for specific components.
// These are used in the Global Site Settings type.
export const DATA_SOURCE_INDEX = 'Index';
export const DATA_SOURCE_FUTURES = 'Futures';

export const NOTIFICATION_GROUPS = [
  'Market Notices',
  'Exchange Updates',
  'Rule Filings'
];

export const FUTURES_MONTH_CODES = {
  January: 'F',
  February: 'G',
  March: 'H',
  April: 'J',
  May: 'K',
  June: 'M',
  July: 'N',
  August: 'Q',
  September: 'U',
  October: 'V',
  November: 'X',
  December: 'Z'
};

export const MONTH_INDICES = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11
};

export const ONE_DAY = 'day';
export const ONE_WEEK = 'week';
export const ONE_MONTH = 'month_daily';

export const SUNDAY = 0;
export const SATURDAY = 6;

// Need to pass in the day prior to the desired day, 05/18/2020, since dxfeed is not returning data
// for 5/18 if that is passed in as the start date to the API
export const FUTURES_START_DATE_FORMATTED = '2020-05-18';
export const FUTURES_START_DATE_FORMAT = 'YYYY-MM-DD';
// The first front month contracts at SMFE used the following month instead of the month in which trading took place.
// This variable is used in building out all futures symbols.
export const FUTURES_START_MONTH = 5;

export const pageModes = {
  OVERVIEW: 'overview',
  SPEC: 'specification',
  QUOTE: 'quote',
  DATA: 'data'
};

export const CTA_POSITIONS = {
  TOP: 'top',
  BOTTOM: 'bottom'
};

export const HEADER_HEIGHT = 108;
export const FOOTER_HEIGHT = 522;

export const NEWS_DATE_FORMAT = 'MM/DD/YYYY';

export const BLOCK_STYLES_SIDE_PADDING_ONLY = {
  block_styles: {
    document: {
      data: {
        padding_top: 0,
        padding_bottom: 0
      }
    }
  }
};

export const YOUTUBE_THUMBNAILS = {
  large: 'maxresdefault',
  medium: 'sddefault',
  small: 'hqdefault'
};

// absurdly long regex to validate email addresses. Slight modification of the expression from http://emailregex.com/, which is compliant with RFC5322 (https://www.ietf.org/rfc/rfc5322.txt)
export const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

export const DEFAULT_AGREEMENT_TEMPLATE = 'website|subscription|20190501';

export const PRIMARY_PURPOSE = 'primary';
export const PHONE_CONTACT_TYPE = 'phone';
export const RECOVERY_PURPOSE = 'recovery';
export const EMAIL_CONTACT_TYPE = 'email';
export const AGREEMENT_STATUS_SIGNED = 'signed';
export const AGREEMENT_STATUS_ACTION_REQUIRED = 'action required';

export const COUNTRY_CODE_US = 'US';
export const ADDRESS_TYPE_HOME = 'home';
export const ADDRESS_TYPE_MAILING = 'mailing';
export const ADDRESS_TYPE_BILLING = 'billing';

export const INVOICE_STATUS_PARTIAL = 'partial';
export const INVOICE_STATUS_PAID = 'paid';
export const INVOICE_STATUS_CREATED = 'created';
export const INVOICE_STATUS_SPONSOR_VERIFYING = 'sponsor_verifying';
export const UNLOCKED_INVOICE_STATUSES = [
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PARTIAL
];
export const PAYABLE_INVOICE_STATUSES = [
  INVOICE_STATUS_CREATED,
  INVOICE_STATUS_PARTIAL
];
export const PORTAL_ACCESS_INVOICE_STATUSES = [
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_SPONSOR_VERIFYING
];

export const DEFAULT_PAYMENT_RETURN_PATH = '/registration/';
export const AWAITING_CONFIRMATION = 'awaiting_confirmation';
export const PENDING_USER_INPUT = 'pending_user_input';
export const LINK_STATE_CONFIRMED = 'confirmed';
export const UNCONFIRMED_STATES = [AWAITING_CONFIRMATION, PENDING_USER_INPUT];

export const registrationStates = {
  INCOMPLETE: 'incomplete',
  PROFILE_UPDATE_REQUIRED: 'profile_update_required',
  AGREEMENTS_REQUIRED: 'agreements_required',
  COMPLETE: 'complete'
};

export const NAV_VISIBILITY = {
  ALWAYS_VISIBLE: 'Always visible',
  SHOW_IF_LOGGED_IN: 'Show if logged in',
  SHOW_IF_LOGGED_OUT: 'Show if logged out',
  SHOW_EXCEPT_PORTAL: 'Show except portal'
};

export const NAV_SPECIAL = {
  SIGN_IN: 'Sign in',
  SIGN_OUT: 'Sign out',
  PROFILE: 'Profile'
};

export const SUBSCRIPTION_STATUS_ACTIVE = 'active';

export const TAG_PORTAL_CHILD_PAGE = 'portal child page';
