import React, { useContext, useState } from 'react';

import GatsbyIpfFileContext from '../context/GatsbyIpfFileContext';
import SiteSettingsContext from '../context/SiteSettingsContext';
import useFetchSymbolHistory from '../hooks/fetchSymbolHistory';
import useSocket from '../hooks/useSocket';
import {
  DATA_SOURCE_FUTURES,
  DATA_SOURCE_INDEX,
  ONE_MONTH
} from '../utils/constants';
import dayjs from '../utils/dayjs';
import IndexContext from './IndexContext';

const defaultState = {
  summaryTradeData: {},
  isSummaryTradeLoading: true,
  isCandleDataLoading: true,
  candleData: {},
  historicalMonthData: []
};
const MarketDataContext = React.createContext(defaultState);
MarketDataContext.displayName = 'MarketDataContext';

const MarketDataProvider = ({
  symbol,
  shouldLoadOptionsData = false,
  children
}) => {
  const [isSummaryTradeLoading, setIsSummaryTradeLoading] = useState(true);
  const [isCandleDataLoading, setIsCandleDataLoading] = useState(true);
  const [summaryTradeData, setSummaryTradeData] = useState({});
  const siteSettings = useContext(SiteSettingsContext);
  const productData = useContext(GatsbyIpfFileContext);
  const indices = useContext(IndexContext);

  const dataSource =
    (siteSettings.products[symbol] &&
      siteSettings.products[symbol].price_movement_widget_data_source) ||
    DATA_SOURCE_INDEX;
  let symbolsToLoad;
  if (dataSource === DATA_SOURCE_FUTURES) {
    const frontMonthSymbol = productData[symbol].frontMonthSymbol;
    const backMonthSymbol = productData[symbol].backMonthSymbol;

    if (shouldLoadOptionsData) {
      // When winding down old products, there will be a period of time where there is no back month data, so we need to null check here
      const backMonthOptions = productData[symbol].backMonthOptions || [];

      symbolsToLoad = [
        frontMonthSymbol,
        backMonthSymbol,
        ...productData[symbol].frontMonthOptions,
        ...backMonthOptions
      ];
    } else {
      symbolsToLoad = [frontMonthSymbol, backMonthSymbol];
    }
  } else {
    symbolsToLoad = [indices[symbol]];
  }

  const symbolsString = symbolsToLoad.join(',');
  const events = shouldLoadOptionsData
    ? 'Summary,Trade,Quote'
    : 'Summary,Trade';
  const summaryTradeRoom = `caxy/rest/eventSource.json?events=${events}&symbols=${symbolsString}&lastEvent=1`;

  useSocket(summaryTradeRoom, (message) => {
    setSummaryTradeData(message);
    setIsSummaryTradeLoading(false);
  });

  const candleSymbols = symbolsToLoad.map((s) => `${s}{=15m}`);
  const frontMonthSymbol = candleSymbols[0];
  const backMonthSymbol = candleSymbols[1];
  const [candleData, setCandleData] = useState({
    [frontMonthSymbol]: [],
    [backMonthSymbol]: []
  });

  const candleRoom = `caxy/rest/eventSource.json?events=Candle&symbols=${candleSymbols.join(
    ','
  )}`;

  useSocket(candleRoom, (message) => {
    const frontMonthData = message[frontMonthSymbol] || {};
    const backMonthData = message[backMonthSymbol] || {};
    const frontMonthCandle = frontMonthData.Candle || [];
    const backMonthCandle = backMonthData.Candle || [];
    const dailyFilter = (value) => {
      // if the current time is before 7am central, then we want to display the previous day's candle data still
      if (
        dayjs()
          .tz()
          .hour() < 7
      ) {
        return true;
      }

      return dayjs(value.time).isSameOrAfter(
        dayjs()
          .tz()
          .hour(7)
          .minute(0)
          .second(0)
          .millisecond(0)
      );
    };

    setCandleData((prev) => {
      const addValue = (monthList, value) => {
        const existingCandleIndex = monthList.findIndex(
          (element) => value.time === element.time
        );
        if (existingCandleIndex >= 0) {
          monthList[existingCandleIndex] = value;
        } else {
          monthList.push(value);
        }
      };

      const frontMonthList = prev[frontMonthSymbol];
      frontMonthCandle.filter(dailyFilter).forEach((value) => {
        addValue(frontMonthList, value);
      });

      const backMonthList = prev[backMonthSymbol];
      backMonthCandle.filter(dailyFilter).forEach((value) => {
        addValue(backMonthList, value);
      });

      return {
        [frontMonthSymbol]: frontMonthList,
        [backMonthSymbol]: backMonthList
      };
    });

    setIsCandleDataLoading(false);
  });

  const historicalDataSource =
    (siteSettings.products[symbol] &&
      siteSettings.products[symbol].historical_graph_data_source) ||
    DATA_SOURCE_INDEX;
  let historicalSymbolsToLoad;

  if (historicalDataSource === DATA_SOURCE_FUTURES) {
    const frontMonthSymbol = productData[symbol].frontMonthSymbol;
    const backMonthSymbol = productData[symbol].backMonthSymbol;

    historicalSymbolsToLoad = [frontMonthSymbol, backMonthSymbol];
  } else {
    historicalSymbolsToLoad = [indices[symbol]];
  }

  const historicalMonthData = useFetchSymbolHistory(
    historicalSymbolsToLoad.join(','),
    ONE_MONTH
  );

  return (
    <MarketDataContext.Provider
      value={{
        summaryTradeData: summaryTradeData,
        isSummaryTradeLoading: isSummaryTradeLoading,
        candleData: candleData,
        isCandleDataLoading: isCandleDataLoading,
        historicalMonthData: historicalMonthData
      }}
    >
      {children}
    </MarketDataContext.Provider>
  );
};

export { MarketDataProvider };

export default MarketDataContext;
